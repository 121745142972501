@import url("antd.layout.min.css");
@import url("palette.css");
@import url("dimentions.css");
@import url("fonts.css");
*,
*::after,
*::before {
  box-sizing: inherit;
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--gray-3) var(--main-gray-2);
}
body,
html {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable */
  font-smoothing: antialiased;
  /* stylelint-enable */
  font-variant-ligatures: none;
  line-height: 1.5;
  scroll-behavior: smooth;
}
section {
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
